<script>
  import { getContext } from "svelte";
  import {
    HERO_COMPONENT_MAP,
    getHeroVariantProps,
    HERO_VARIANTS_WITH_THEME
  } from "$lib/utilities/contentTypes/hero";
  import Hero from "$lib/components/Hero.svelte";
  import { heroThemeStore } from "$lib/stores";
  import { BREAK_POINTS } from "$lib/utilities/constants";
  import { afterUpdate } from "svelte";

  export let data;

  let formFactor = data.fields?.formFactor;

  const component = HERO_COMPONENT_MAP[formFactor];
  const pageDataContext = getContext("pageData");

  /** @type {[]string} hard-coded list of heroes to override with demForum2024 theme */
  const heroesToOverride = ["tIMDpWvPcFrNiz4T5CiK5", "1tqJiTjXOB1FI7B03IHJHT"];
  const propOverrides = heroesToOverride.includes(data.sys?.id) ? { theme: "demForum2024" } : {};

  let screenWidth = 0;

  // The image displayed on the hero can depend on the current breakpoint.
  $: breakpointAdjustedFields =
    screenWidth < BREAK_POINTS.md && data.fields.mobileFeaturedImage
      ? { ...data.fields, featuredImage: data.fields.mobileFeaturedImage }
      : data.fields;

  $: heroProps = {
    ...getHeroVariantProps(breakpointAdjustedFields, pageDataContext),
    ...propOverrides
  };

  let hasThemedBackground = HERO_VARIANTS_WITH_THEME.includes(formFactor);

  afterUpdate(() => {
    if (hasThemedBackground) {
      // Used to update the the Global Navigation background color based on current Home Hero background color
      heroThemeStore.set(heroProps?.theme ?? "white");
    } else {
      heroThemeStore.set("white");
    }
  });
</script>

<svelte:window bind:innerWidth={screenWidth} />

<Hero data={heroProps} {component} />
